<template>
<div class="all">
  <div class="photo">
   <img src="../src/assets/image/jindu.png" alt="">
   <p>待受理</p>
  </div>
  <div class="length">
  </div>

   <div class="photo">
   <img src="../src/assets/image/jindu.png" alt="">
   <p>已受理</p>
  </div>
  <div class="length">
  </div>

   <div class="photo">
   <img src="../src/assets/image/jindu.png" alt="">
   <p>审核中</p>
  </div>
  <div class="length">
  </div>

   <div class="photo">
   <img src="../src/assets/image/jindu.png" alt="">
   <p>已审核</p>
  </div>
  <div class="length">
  </div>

   <div class="photo-a">
   <img src="../src/assets/image/jindu.png" alt="">
   <p>已结案</p>
  </div>


</div>
</template>
<script>
export default {
  name:'v-step'
}
</script>
<style scope>
*{
  margin: 0;
  padding: 0;
}
.all{
  display: flex;
  justify-content: end;

}
  .photo img{
    margin-left: 0.16rem;
    width: 0.42rem;
   	height: 0.42rem;
   	opacity: 0.63;
  }
  .photo-a img{
     margin-left: 0.16rem;
    width: 0.42rem;
   	height: 0.42rem;
   	opacity: 0.99;
  }
  .photo-a p{
        margin-top: 0.08rem;
    font-family: PingFang-SC-Medium;
	font-size: 0.24rem;
	font-weight: normal;
	font-stretch: normal;
	line-height: 0.36rem;
	letter-spacing: 0rem;
	color: #47a3ff;
  }
  .photo p{
        margin-top: 0.08rem;
  font-family: PingFang-SC;
	font-size: 0.24rem;
	font-weight: normal;
	font-stretch: normal;
	line-height: 0.36rem;
	letter-spacing: 0rem;
	color: #666666;
  }
  .length{
   margin-top: 0.2rem;
    width: 0.5rem;
	height: 0.02rem;
	background-color: #e5e5e5;
  }
</style>

