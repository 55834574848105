
import vStep from '../../../components/step.vue'


export default {
  components: {
    vStep
  },
  data() {
    return {
      // active: 1,
      success1: require('../../assets/image/jindu.png'),
      ss: require('../../assets/image/jindu.png'),
    };

  },
  methods: {
    insuranceDetail() { // 理赔详情
      this.$router.push({ path: '/home/insuranceClaims/insuranceDetail' });
    },
    back() {
      this.$router.go(-1);
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  },
};
